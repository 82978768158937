import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createShippingDocument,
  deleteShipmentDoc,
  getBatchItems,
  getBatchesOfShipment,
  getRFIDScansItems,
  getShippingDocument,
  getShippingDocumentDetails,
  getShippingDocumentDetailsById,
  shipmentDocumentDownload,
} from "api/shippingV3.api";
import { notificationController } from "controllers/notificationController";

export interface ShippingSlice {
  shipping_document_list: any;
  shipping_batch_items: any;
  shipping_batch_items_loading: boolean;
  shipping_rfid_scans_items: any;
  shipping_rfid_scans_items_loading: boolean;
  shipping_document_details: any;
  shipping_document_list_loaded: boolean;
  allow_barcode_Scanning: boolean;
  rfid_Scanner_connected: boolean;
  document_item_sorting: any;
  missing_items_sorting: any;
  selected_batch_id: number | string | null
  selected_batch_or_rfid: number | string | null
  shipment_client_id: number | null
}

const initialState: ShippingSlice = {
  shipping_document_list: [],
  shipping_document_list_loaded: false,
  shipping_document_details: null,
  shipping_batch_items: [],
  shipping_batch_items_loading: false,
  shipping_rfid_scans_items: [],
  shipping_rfid_scans_items_loading: false,
  allow_barcode_Scanning: false,
  rfid_Scanner_connected: true,
  document_item_sorting: "",
  missing_items_sorting: "",
  selected_batch_id: null,
  selected_batch_or_rfid: null,
  shipment_client_id: null
};

export const doGetShippingDocument = createAsyncThunk(
  "shippingDocument/doGetShippingDocument",
  async (data: any, { dispatch }) =>
    getShippingDocument(data).then((res: any) => {
      return res;
    })
);
export const doShippingDocumentDownload = createAsyncThunk(
  "shippingDocument/doShippingDocumentDownload",
  async (data: any, { dispatch }) =>
    shipmentDocumentDownload(data).then((res: any) => {
      return res;
    })
);
export const doGetBatchItems = createAsyncThunk(
  "shippingDocument/doGetBatchItems",
  async (data: any) => {
    const res = await getBatchItems(data);
    return {...res,  batchNumber: data?.shipment_batch_id };
  }
);
export const doGetRFIDScansItems = createAsyncThunk(
  "shippingDocument/doGetRFIDNoBatchItems",
  async (data: any) => {
    const res:any = await getRFIDScansItems(data);
    return res;
  }
);

export const doGetShippingDocumentDetails = createAsyncThunk(
  "shippingDocument/doGetShippingDocumentDetails",
  async (id) =>
    getShippingDocumentDetails(id).then((res: any) => {
      return res;
    })
);
export const doGetShippingDocumentDetailsById = createAsyncThunk(
  "shippingDocument/doGetShippingDocumentDetailsById",
  async (id: any) =>
    getShippingDocumentDetailsById(id).then((res: any) => {
      return res;
    })
);

export const doGetDocumentDetails = createAsyncThunk(
  "shippingDocument/doGetDocumentDetails",
  async (id: any) =>
    getShippingDocumentDetails(id).then((res: any) => {
      return res;
    })
);

export const doGetBatchesOfDocument = createAsyncThunk(
  "shippingDocument/doGetNewScansOfDocument",
  async (id: any, { dispatch }) =>
    getBatchesOfShipment(id).then((res: any) => {
      return res;
    })
);

export const doDeleteShipmentDoc = createAsyncThunk(
  "shippingDocument/doDeleteShipmentDoc",
  async (id: any, { dispatch }) =>
    deleteShipmentDoc(id).then((res) => {
      return res
    })
);

// export const doCreateShippingDocument = createAsyncThunk(
//   "auth/doCreateShippingDocument",
//   async (shipmentPayload: any, { dispatch }) =>
//     createShippingDocument(shipmentPayload).then((res: any) => {
//       dispatch(doGetShippingDocument());
//       return res;
//     })
// );

export const updateAllowBarcodeScanState = createAsyncThunk(
  "shippingDocument/updateAllowBarcodeScanState",
  async (scanState: boolean, { dispatch }) => {
    dispatch(setAllowBarcodeScanState(scanState));
  }
);
export const updateRfidScannerConnected = createAsyncThunk(
  "shippingDocument/updateRfidScannerConnected",
  async (scanState: boolean, { dispatch }) => {
    dispatch(setRfidScannerConnected(scanState));
  }
);

export const clearShippingDocumentDetails = createAction('shippingDocumentDetails/clear');
// export const clearShippingBatchItems = createAction('shippingBatchItems/clear');
export const clearShippingRFIDScans = createAction('shippingRFIDScans/clear');
export const clearSelectedBatchId = createAction('selectedBatchId/clear');

const shippingSlice = createSlice({
  name: "shippingDocument",
  initialState,
  reducers: {
    setAllowBarcodeScanState: (state, action) => {
      state.allow_barcode_Scanning = action.payload;
    },
    setRfidScannerConnected: (state, action) => {
      state.rfid_Scanner_connected = action.payload;
    },
    setDocumentItemSorting: (state, action) =>{
      state.document_item_sorting = action?.payload
    },
    setMissingItemsSorting: (state, action) =>{
      state.missing_items_sorting = action?.payload
    },
    setSelectedBatchId: (state, action) =>{
      state.selected_batch_id = action?.payload
    },
    setIsBatchOrRFIDClicked: (state, action) =>{
      state.selected_batch_or_rfid = action?.payload
    },
    setShipmentClientId: (state, action) =>{
      state.shipment_client_id = action?.payload
    },
    clearShippingBatchItems: (state) => {
      state.shipping_batch_items = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(doGetShippingDocument.fulfilled, (state, action) => {
      state.shipping_document_list = action.payload;
      state.shipping_document_list_loaded = true;
    });
    builder.addCase(doGetBatchItems.pending, (state) => {
      state.shipping_batch_items_loading = true;
    });
    builder.addCase(doGetBatchItems.fulfilled, (state, action) => {
      state.shipping_batch_items = action.payload;
      state.shipping_batch_items_loading = false;
    });
    builder.addCase(doGetRFIDScansItems.pending, (state) => {
      state.shipping_rfid_scans_items_loading = true;
    });
    builder.addCase(doGetRFIDScansItems.fulfilled, (state, action) => {
      state.shipping_rfid_scans_items = action.payload;
      state.shipping_rfid_scans_items_loading = false;
    });
    builder.addCase(doGetShippingDocumentDetailsById.fulfilled, (state, action) => {
      state.shipping_document_details = action.payload;
    });
    builder.addCase(clearShippingDocumentDetails, (state) => {
      state.shipping_document_details = null;
    });
    builder.addCase(clearShippingBatchItems, (state) => {
      state.shipping_batch_items = null;
    });
    builder.addCase(clearShippingRFIDScans, (state) => {
      state.shipping_rfid_scans_items = null;
    });
    builder.addCase(clearSelectedBatchId, (state) => {
      state.selected_batch_id = null;
    });
  },
});
export const { 
  setAllowBarcodeScanState,
  setRfidScannerConnected,
  setDocumentItemSorting,
  setSelectedBatchId,
  setIsBatchOrRFIDClicked,
  clearShippingBatchItems,
  setMissingItemsSorting,
  setShipmentClientId
} = shippingSlice.actions;
export default shippingSlice.reducer;
