import React, { useEffect } from 'react'
import { Button, Form, Input, Modal, Space } from 'antd';
import { attachRFID, getRFIDReaders } from 'api/shipping.api';
import DropdownWithServerData from 'components/common/dropdownWithServerData/DropdownWithServerData';
import { CloseOutlined } from "@ant-design/icons";
import { notificationController } from 'controllers/notificationController';
import { updateRfidScannerConnected } from 'store/slices/shippingDocumentSlice';
import { useAppDispatch } from 'hooks/reduxHooks';

interface Props {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  rfidAttachModelVisible: boolean;
  setRFIDAttachRequired: React.Dispatch<React.SetStateAction<boolean>>;
  setRfidAttachModelVisible: React.Dispatch<React.SetStateAction<boolean>>;
  reloadScanners?: () => void;
  defaultReader?: {
    attached: string;
    label: string;
    key: number
  };
  fetchUpdatedMenuData?: () => void
}
const RfidAttachModel = ({
  isLoading,
  setIsLoading,
  rfidAttachModelVisible,
  setRFIDAttachRequired,
  setRfidAttachModelVisible,
  defaultReader,
  reloadScanners,
  fetchUpdatedMenuData,
}: Props) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const handleSubmit = (values: any) => {
    setIsLoading(true)
    attachRFID(values).then(
      () => {
        notificationController.success({
          message: "RFID attached successfully",
        });
        dispatch(updateRfidScannerConnected(true))
        setRfidAttachModelVisible(false);
        setIsLoading(false)
        if (reloadScanners) reloadScanners(); else setRFIDAttachRequired(false);
          fetchUpdatedMenuData?.()
      },
      (err: any) => {
        setIsLoading(false)
        notificationController.error({ message: err.message });
      }
    );
  };

  const filterData = (data: any) => {
    if (!data) return [];
    return data.map((item: any) => ({
      label: item.name,
      value: item.id,
      key: item.id,
    }));
  };

  useEffect(() => {
    form.setFieldValue("rfid_reader_id", defaultReader?.key);
  }, [defaultReader])

  return (
    <Modal
      title="RFID reader"
      footer={null}
      visible={rfidAttachModelVisible}
      onCancel={() => {
        setRfidAttachModelVisible(false);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item label="Please attach RFID before scan."
          name="rfid_reader_id"
          rules={[{ required: true }]}>
          <DropdownWithServerData
            className="sidebar"
            style={{ width: "100%" }}
            placeholder="Select RFID reader"
            defaultValue={defaultReader?.label}
            optionFilterProp="children"
            apiFunction={getRFIDReaders}
            default
            filterFunction={(data: any) => {
              return filterData(data);
            }}
          ></DropdownWithServerData>
        </Form.Item>
        <Form.Item label="Verification Code" name="tl_verification_code" rules={[{ required: true }]}>
          <Input placeholder="Enter Name" name="name" autoComplete="off" />
        </Form.Item>
        <div className='justify-end'>
          <Space>
            <Button
              loading={isLoading}
              htmlType="submit"
              type={"primary"}
            >
              Scan Now
            </Button>
            <Button
              type="default"
              icon={<CloseOutlined />}
              onClick={() => setRfidAttachModelVisible(false)}
            >
              Cancel
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  )
}

export default RfidAttachModel