import React, { useState, useEffect } from "react";
import { useDynamicShipmentTitle } from "hooks/useDynamicShipmentTitle";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getProfileID } from "services/localStorage.service";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import { StatusColor } from "utils/utils";
import ScannersList from "components/shipmentDocument/shipmentScanners/ScannersList";
import ScannersListV2 from "components/shipmentDocumentV2/shipmentScanners/ScannersList";
import ScannersListV3 from "components/shipmentDocumentV3/shipmentScanners/ScannersList";
import WorkSchedulesHeader from "components/workSchedules/workSchedulesHeader/WorkSchedulesHeader";
import BackIcon from "icons/svg/BackIcon";
import "./LayoutHeader.css";
import { getSelectedTab, setSelectedTab } from "store/slices/refreshTabsSlice";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;
interface KeyToPathMap {
  [key: string]: string;
}
const keyToPathMap: KeyToPathMap = {
  "1": "/client/list",
  "2": "/client/agreement/list",
  "3": "/client/professional/list",
  "4": "/client/pricing-list-items/list",
  "5": "/client/bulk-items/list",
  "6": "/client/plan-templates/list",
};
interface LayoutHeaderProps {
  isClient?: boolean;
}
const LayoutHeader: React.FC = () => {
  const { shipping_document_details } = useAppSelector(
    (state) => state.shippingDocument
  );
  
  const title = useDynamicShipmentTitle(shipping_document_details);
  const { pathname } = useLocation();
  const isClientPage = pathname.split("/")[1] === "client";
  const [activeKey, setActiveKey] = useState("1");
  const navigate = useNavigate();
  const profileID = getProfileID();
  const isWorkSchedule = pathname === "/work-schedule";
  const dispatch = useAppDispatch();
  const path = pathname.split("/")
  const isShipmentPage = path[1] === 'shipment-document' && path[2] === 'details'
  const isShipmentPageV2 = path[1] === 'shipment-document-v2' && path[2] === 'details'
  const isShipmentPageV3 = path[1] === 'shipment-document-v3' && path[2] === 'details'

  useEffect(() => {
    if (!isClientPage) return;
    const firstKey = Object.keys(keyToPathMap)[0];
    const storedKey = localStorage.getItem("activeTab");
   
    if (storedKey) setActiveKey(storedKey);
    if (pathname === keyToPathMap[firstKey]) setActiveKey(firstKey);
  }, [pathname]);

  const handleTabChange = (key: string) => {
    const randomIndex = Math.floor(Math.random() * 100);
    dispatch(setSelectedTab(key + " " + randomIndex!!));
    setActiveKey(key);
    localStorage.setItem("activeTab", key);
    if (keyToPathMap[key]) {
      navigate(keyToPathMap[key]);
    }
  };

  const renderClientTabs = () => {
    const tabs = profileID === 1 ? (
      <>
        <TabPane tab="Clients" key="1" />
        <TabPane tab="Plans" key="2" />
        <TabPane tab="Professional" key="3" />
        <TabPane tab="COG" key="4" />
        <TabPane tab="Bulk Items" key="5" />
        <TabPane tab="Professional Setups" key="6" />
      </>
    ) : (
      <>
        {/* <TabPane tab="Plans" key="2" /> */}
        <TabPane tab="Professional" key="3" />
      </>
    );

    return (
      <div className="-mb-18">
        <Tabs activeKey={activeKey} onTabClick={handleTabChange} >
          {tabs}
        </Tabs >
      </div >
    );
  };


  const showAdditionalDetailsOnHeader = () => {
    if (!!shipping_document_details) {
      if(isShipmentPageV2){
        return <ScannersListV2 />
      } else if (isShipmentPageV3){
        return <ScannersListV3/>
      } else {
        return <ScannersList />;
      }
    }
    if (isWorkSchedule) return <WorkSchedulesHeader />;
  };

  const renderBackButton = () => {
    return (
      <div className="flex-end">
        <button onClick={() => navigate(`shipment-document-${isShipmentPageV3 ? "v3" : "v2"}/list`)} className="back-btn">
          <BackIcon />
        </button>
      </div>
    );
  }

  const renderTitleSection = () => {
    return (
      <div className="title-section" >
        <h1 className="header-text" id="page-title"></h1>
        <p className="nested-header-text">
          <div
            className="header-status-circle"
            style={{
              background:
                StatusColor[
                shipping_document_details?.shipment_status_value
                ],
            }}
          />
          {title}
        </p>
      </div>
    );
  }

  return (
    <>
      <div className={`site-layout-background 
      ${shipping_document_details ? "fixed-header" : ""} 
      ${isWorkSchedule ? "justify-start" : "justify-between"}`}
      >
        <div className={`flex ${isClientPage ? 'items-center' : ''}`}>
          {!!shipping_document_details && renderBackButton()}
          {isClientPage ? renderClientTabs() : renderTitleSection()}
        </div>
        {showAdditionalDetailsOnHeader()}
      </div>
      {shipping_document_details && <div className="invisible" />}
    </>
  );
};

export default LayoutHeader;
